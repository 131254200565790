import React, { useState, useEffect } from "react";
import classes from './Executives.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import footerLogo from "../../assets/Footer-logo.svg"
import { BASE_URL } from "../../BaseUrl/BaseUrl";
import NewsIcon from "../../assets/olumo.png"
import NewFooter from "../../Components/NewFooter";
import NewFooter2 from "../../Components/NewFooter2";

// function App() {
const NewsDetails = () => {
  const location = useLocation();
  const {selectedNews} = location.state || {};
  console.log(selectedNews);
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');
  const imageUrl = selectedNews?.banner ? BASE_URL + selectedNews.banner : null;

  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);


  function formatDate(dateString) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
  }




  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper}>
      
        <div  className={classes.topSection}>
          <h1 className={classes.newsTitle}>{selectedNews?.title}</h1>
          <p>{selectedNews?.short_desc}</p>
          {selectedNews?.banner ? (
        <img
          src={selectedNews?.banner}
          alt="news"
         
          style={{ objectFit: 'contain', width: '100%', height: 400 }}
        />
      ) : (
        <img
          src={NewsIcon}
          alt="news icon"
          style={{ objectFit: 'cover', width: '100%', height: 400 }}
        />
      )}
          <div className={classes.postedSection}>
           <p className={classes.date}>Posted {formatDate(selectedNews?.created_date)}</p>
           <p dangerouslySetInnerHTML={{ __html: selectedNews?.long_desc }} className={classes.mainNews}></p>
          </div>

          
        </div>

      </div>
      <NewFooter2/>
    </div>
  );
}

export default NewsDetails;