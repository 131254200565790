import React, {useEffect} from 'react';
import {  Route, Routes  } from 'react-router-dom';
import Page2 from './Mdas.js';
import MDAs from './Pages/MinistryUrban/MinistryUrban.js';
import Departments from './departments.js';
import Archive from './Pages/Archive/Archive.js';
import MdasMoa from './Pages/MDAs MOA/mdas_moa.js';
import Mdas from './Mdas.js';
import MinistryUrban from './Pages/MinistryUrban/MinistryUrban.js';
import Agencies from './Agencies.js';
import { useLocation } from 'react-router-dom';
import Executives from './Pages/Executives/Executives.js';
import Legends from './Pages/Legends/Legends.js';
import LegendDetails from './Pages/LegendDetails/LegendDetails.js';
import LandingPage from './Pages/Landingpage/landingPage.js'
import ArchiveNew from './Pages/ArchivesNew/ArchiveNew.js';
import Budgets from './Pages/Budgets Documents/Budgets.js';
import Financial from './Pages/Financial Statements/Financial.js';
import ExecutivesDetails from './Pages/Executives Details/ExecutivesDetails.js';
import News from './Pages/News/News.js';
import NewsDetails from './Pages/News/NewsDetails.js';
import Government from './Pages/Government/Government.js';
import Auditor from './Pages/Financial Statements/Auditor.js';
import LocalGovernment from './Pages/Financial Statements/LocalGovernment.js';
import Mtef from './Pages/Financial Statements/MTEF.js';
import PublicProcurement from './Pages/Financial Statements/PublicProcurement.js';
import NewsMdas from './Pages/News Mdas/News.js';
import NewsDetailsMdas from './Pages/News Mdas/NewsDetails.js';
import OgunInvest from './Pages/Ogun Invest/OgunInvest.js';
import Contract from './Pages/Financial Statements/Contract.js';
import ExecutivesDetails2 from './Pages/Executives Details/ExecutivesDetails2.js';
import ExecutivesDetails3 from './Pages/Executives Details/ExecutivesDetails3.js';


function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (

    
    <Routes>
      {/* <Route path='/' element={<lan />}/> */}
      <Route path='/' element={<LandingPage/>}/>
      <Route path='/ministry_details' element={<MinistryUrban />}/>
      <Route path='/ogun_invest' element={<OgunInvest />}/>
      <Route path='/mdas_moa' element={<MdasMoa />}/>
      <Route path='/ministry_department_agencies' element={<Mdas />}/>
      <Route path='/departments' element={<Departments />}/>
      <Route path='/agencies' element={<Agencies />}/>
      <Route path='/contracts_&_adverts' element={<Contract />}/>
      <Route path='/Archives' element={<Archive />}/>
      <Route path='/ogstep_documents' element={<ArchiveNew />}/>
      <Route path='/ministry_of_budgets_documents' element={<Budgets />}/>
      <Route path='/financial_statements' element={<Financial />}/>
      <Route path='/state_auditors_report' element={<Auditor />}/>
      <Route path='/local_govt_financial_reports' element={<LocalGovernment />}/>
      <Route path='/mtef_reports' element={<Mtef />}/>
      <Route path='/public_procurement' element={<PublicProcurement />}/>
      <Route path='/executives' element={<Executives />}/>
      <Route path='/news' element={<News />}/>
      <Route path='/news_mdas' element={<NewsMdas />}/>
      <Route path='/read_news' element={<NewsDetails />}/>
      <Route path='/read_news_mdas' element={<NewsDetailsMdas />}/>
      <Route path='/executive_profile' element={<ExecutivesDetails />}/>
      <Route path='/noimot_salako_profile' element={<ExecutivesDetails2 />}/>
      <Route path='/joseph_talabi_profile' element={<ExecutivesDetails3 />}/>
      <Route path='/legends' element={<Legends />}/>
      <Route path='/legend_details' element={<LegendDetails />}/>
      <Route path='/open_government' element={<Government />}/>
    </Routes>
 
  );
}

export default App;
