import React, { useState, useEffect, useLayoutEffect } from "react";
import classes from './Executives.module.css';
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import HeaderNav2 from "../../Components/HeaderNav/HeaderNav2";
import NewFooter2 from "../../Components/NewFooter2";
import NewsIcon from "../../assets/olumo.png";

const NewsMdas = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { news } = location.state || [];

  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  const entriesPerPage = 20;
  const totalEntries = news.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  useLayoutEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const formatDate = (dateString) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };

  const startIndex = (currentPage - 1) * entriesPerPage;
  const currentNews = news.slice(startIndex, startIndex + entriesPerPage);

  return (
    <div>
      <HeaderNav2 />

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <h1>News</h1>
          <p className={classes.instruction}>Read the latest news from Ogun State</p>
          <div className={classes.newGrid}>
            {currentNews.length === 0 ? (
              <p>No news to display</p>
            ) : (
              currentNews.map((item, index) => (
                <div key={index} className={classes.topSectionLabel}>
                  <div className={classes.newsSection}>
                    <img
                      src={NewsIcon}
                      alt="news"
                      style={{ objectFit: 'contain', width: 170, height: 170, borderRadius: 4 }}
                      className={classes.newImg}
                    />
                    <div className={classes.newsText}>
                      <h1 className={classes.newsTitle}>{item?.title} </h1>
                      <p>{item?.short_desc}</p>
                      <div className={classes.footerButton}>
                        <p>Posted on {formatDate(item.created_date)}</p>
                        <h6 onClick={() => navigate('/read_news', { state: { selectedNews: item } })}>Read more...</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          <div className={classes.endded}>
            <div style={{ display: 'flex' }}>
              {!isMobile && (
                <button
                  style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              )}

              {[...Array(totalPages)].map((_, page) => {
                if (!isMobile && (page < 5 || page === currentPage - 1 || page === totalPages - 1)) {
                  return (
                    <button
                      key={page + 1}
                      style={{
                        marginLeft: '0.4rem',
                        marginRight: '0.4rem',
                        fontSize: '14px',
                        fontFamily: 'nunito',
                        fontWeight: 400,
                        color: page + 1 === currentPage ? '#ffffff' : '#000',
                        backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                        height: '2.5rem',
                        borderRadius: '89px',
                        padding: '0.5rem',
                        border: 'none',
                        width: '40px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        fontFamily: 'inter'
                      }}
                      onClick={() => {
                        setCurrentPage(page + 1);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {page + 1}
                    </button>
                  );
                }
                return null;
              })}

              {!isMobile && (
                <button
                  style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              )}

              {isMobile && (
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewFooter2 />
    </div>
  );
};

export default NewsMdas;
